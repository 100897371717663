import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, Flex } from '@chakra-ui/react';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { TranslatedMessage } from '../../i18n';
import { defineMessages } from 'react-intl';

const notificationMessage = defineMessages({
  title: {
    id: 'NotificationBanner.subscription_announcement_title',
    defaultMessage: 'Anuncio importante',
  },
  description: {
    id: 'NotificationBanner.subscription_announcement_description',
    defaultMessage:
      'Queremos recordarte que en los próximos dias, algunas funcionalidades de nuestra plataforma estarán disponibles sólo a través de planes de suscripción. Pronto podrás elegir el que mejor se adapte a vos. Si tenés consultas, estamos para ayudarte.',
  },
});

export const NotificationBanner: React.FC = () => {
  const [isVisible, setIsVisible] = useLocalStorage('notificationBannerVisible', { hidden: false });

  if (isVisible?.hidden) {
    return null;
  }

  const onClose = () => {
    setIsVisible({ hidden: true });
  };

  return (
    <Alert justifyContent={'space-between'} position="fixed" bottom={0} width="full" status="info" zIndex="tooltip" p={4} variant="solid">
      <Flex>
        <AlertIcon alignSelf={'center'} />
        <Box>
          <AlertTitle>
            <TranslatedMessage message={notificationMessage.title} />
          </AlertTitle>
          <AlertDescription>
            <TranslatedMessage message={notificationMessage.description} />
          </AlertDescription>
        </Box>
      </Flex>
      <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} onClick={onClose} />
    </Alert>
  );
};
