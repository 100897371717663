import { isOlderThanNMonthsAgo } from './date-utils';
import { assertUnreachable, validateNonEmptyObject, validateNumber, validateObjectKey, validateString } from './validations';

/**
 * This seems unnecessary right now but lets
 * leave it ready for supporting other types
 * or providers
 */
export const SUBSCRIPTION_VERSION = 1;
export enum SubscriptionProvider {
  MERCADO_PAGO = 'Mercado Pago',
  STRIPE = 'Stripe',
}

export enum SubscriptionType {
  RECURRING = 'recurring',
}

export enum MercadoPagoSubscriptionStatus {
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
  PAUSED = 'paused',
  CANCELED = 'cancelled',
}

export enum MercadoPagoSubscriptionPlanStatus {
  ACTIVE = 'active',
  CANCELED = 'cancelled',
}

export enum StripeSubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLPETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  PAUSED = 'paused',
}

export const isValidMercadoPagoSubscriptionStatus = (value: string): value is MercadoPagoSubscriptionStatus => {
  return Object.values(MercadoPagoSubscriptionStatus).includes(value as MercadoPagoSubscriptionStatus);
};

export const isValidMercadoPagoSubscriptionPlanStatus = (value: string): value is MercadoPagoSubscriptionPlanStatus => {
  return Object.values(MercadoPagoSubscriptionPlanStatus).includes(value as MercadoPagoSubscriptionPlanStatus);
};

export const isValidStripeSubscriptionStatus = (value: string): value is StripeSubscriptionStatus => {
  return Object.values(StripeSubscriptionStatus).includes(value as StripeSubscriptionStatus);
};

export enum UnifiedProviderErrorCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  REQUEST_FAILED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
}

export function isValidProviderErrorCode(errorCode: number): errorCode is UnifiedProviderErrorCode {
  return Object.values(UnifiedProviderErrorCode).includes(errorCode);
}

export function validateSubscriptionType(type: unknown): asserts type is SubscriptionType {
  validateString(type);

  if (!Object.values(SubscriptionType).includes(type as SubscriptionType)) {
    throw new Error('Invalid subscription type');
  }
}

export function validateSubscriptionProvider(provider: unknown): asserts provider is SubscriptionProvider {
  validateString(provider);

  if (!Object.values(SubscriptionProvider).includes(provider as SubscriptionProvider)) {
    throw new Error('Invalid subscription provider');
  }
}

export function isValidSubscriptionProvider(provider: string): provider is SubscriptionProvider {
  return Object.values(SubscriptionProvider).includes(provider as SubscriptionProvider);
}

/**
 * We are going to implement a worker to update subscriptions.
 * The update condition is determined by a job processed by lambdas.
 * Therefore, it is not advisable to make API calls to Stripe or Mercado Pago from the lambdas.
 * Instead, we will trigger update events whenever a device or module is synchronized or deleted.
 * (I say synchronized because it's not enough to just add it; I need to confirm that the variables are functional to charge for them).
 * Also, when one of the plan prices changes.
 * Although some events may trigger the recalculation of multiple subscriptions,
 * it is better to trigger n events to the SQS queue and process them one by one.
 * This way, if one fails, the message will simply remain in the queue and be reprocessed.
 * Otherwise, recovering from a failure when the number of subscriptions to update is large is much more difficult
 * as it will try to recalculate everything again. Therefore, there will only be one type for now, recalculate_subscription,
 * with a payload corresponding to the organization. Each recalculation will be processed one by one in the service.
 *
 * Below, we define the enums and types that will serve as interfaces for these update events stored in SQS.
 * Additionally, a validator with asserts is created to ensure that the received data contains the necessary structure.
 *
 * For more information, please refer to the subscriptions documentation in docs
 */

export enum SubscriptionUpdateEventType {
  RECALCULATE_SUBSCRIPTION = 'recalculate_subscription',
}

export interface RecalculateSubscriptionPayload {
  organization: number;
}

export interface RecalculateSubscriptionEvent {
  type: SubscriptionUpdateEventType.RECALCULATE_SUBSCRIPTION;
  payload: RecalculateSubscriptionPayload;
}

export type SubscriptionUpdateEvent = RecalculateSubscriptionEvent;

export function validateUpdateEventType(type: unknown): asserts type is SubscriptionUpdateEventType {
  if (!Object.values(SubscriptionUpdateEventType).includes(type as SubscriptionUpdateEventType)) {
    throw new Error('Invalid update event type');
  }
}

export function validateRecalculateSubscriptionEventPayload(payload: unknown): asserts payload is RecalculateSubscriptionPayload {
  validateNonEmptyObject(payload);

  validateObjectKey(payload, 'organization');
  validateNumber(payload.organization);
}

export function validateSubscriptionUpdateEvent(event: unknown): asserts event is SubscriptionUpdateEvent {
  validateNonEmptyObject(event);
  validateObjectKey(event, 'type');
  validateObjectKey(event, 'payload');

  const { type, payload } = event;

  // this validates in runtime
  validateUpdateEventType(type);

  switch (type) {
    case SubscriptionUpdateEventType.RECALCULATE_SUBSCRIPTION:
      validateRecalculateSubscriptionEventPayload(payload);
      break;

    // this line ensures the linter gets mad at me
    default:
      assertUnreachable('Invalid event type', type);
  }
}

export function createRecalculateSubscriptionEvent(organization: number): SubscriptionUpdateEvent {
  return {
    type: SubscriptionUpdateEventType.RECALCULATE_SUBSCRIPTION,
    payload: { organization },
  };
}

/**
 * Pricing
 */

/**
 * Calculates the total subscription price based on a minimum price,
 * a minimum number of variables, and a price per additional variable.
 * NOTE: Prices are always in cent
 */
export const calculatePrice = (variableCount: number, pricePerVariable: number, minQuantity?: number, minPrice?: number) => {
  // price without min restrictions
  const linearCalculation = variableCount * pricePerVariable;

  // check valid min price
  if (minPrice !== undefined && minPrice > 0) {
    // check valid min quantity
    if (minQuantity !== undefined && minQuantity > 0) {
      // calculate remaining variables
      const remainingVariables = variableCount - minQuantity;

      if (remainingVariables > 0) {
        // calculate full price
        return minPrice + remainingVariables * pricePerVariable;
      } else {
        // only the min
        return minPrice;
      }
    } else {
      // get actual min price
      const actualPrice = Math.max(minPrice, linearCalculation);

      return actualPrice;
    }
  } else {
    // this will ensure no zero price in case user has no variables
    if (minQuantity !== undefined && minQuantity > 0) {
      // get actual variable count
      const actualVariableCount = Math.max(minQuantity, variableCount);

      return actualVariableCount * pricePerVariable;
    } else {
      // linear calculation
      return linearCalculation;
    }
  }
};

export const calculateBillableVariables = (variableCount: number, minQuantity?: number) => {
  // check valid min quantty
  if (minQuantity !== undefined && minQuantity > 0) {
    // no billable variables if less than min
    if (minQuantity > variableCount) {
      return 0;
    } else {
      // otherwise, calculate diff
      return variableCount - minQuantity;
    }
  } else {
    // if no minimum, just the actual count
    return variableCount;
  }
};

interface Plan {
  provider: string;
  maxUsersRestriction: number | null;
  dataAgeRestrictionInMonths: number | null;
}
interface Subscription {
  status: string;
  Plan: Plan;
}

export const UNSUBSCRIBED_DATA_AGE_RESTRICTION_IN_MONTHS = 1;
export const UNSUBSCRIBED_N_OF_USERS_RESTRICTION = 1;

/**
 * Restriction and status validations
 */

/**
 * These functions check for valid providers and active statuses to ensure clarity in the rest of the code.
 */

export const isActiveStatusBasedOnProvider = (provider: SubscriptionProvider, status: string) => {
  switch (provider) {
    case SubscriptionProvider.MERCADO_PAGO:
      return status === MercadoPagoSubscriptionStatus.AUTHORIZED;

    case SubscriptionProvider.STRIPE:
      return status === StripeSubscriptionStatus.ACTIVE || status === StripeSubscriptionStatus.TRIALING;

    default:
      assertUnreachable('Unknown subscription provider', provider);
  }
};

export const isActiveSubscription = (subscription: Subscription | null): subscription is Subscription => {
  if (subscription === null) {
    return false;
  }
  if (isValidSubscriptionProvider(subscription.Plan.provider)) {
    return isActiveStatusBasedOnProvider(subscription.Plan.provider, subscription.status);
  }

  return false;
};

export const canCancelSubscription = (subscription: Subscription | null): boolean => {
  if (subscription === null) {
    return false;
  }

  if (isValidSubscriptionProvider(subscription.Plan.provider)) {
    const activeStatus = isActiveStatusBasedOnProvider(subscription.Plan.provider, subscription.status);
    const isMercadoLibrePendingStatus =
      subscription.Plan.provider === SubscriptionProvider.MERCADO_PAGO && subscription.status === MercadoPagoSubscriptionStatus.PENDING;

    return activeStatus || isMercadoLibrePendingStatus;
  }

  return false;
};

/**
 * Validates if the required data age is permissible based on the subscription's status and plan restrictions.
 * in case subscription does not exist, it should check the default restriction
 */
export const isValidRequiredDataAge = (subscription: Subscription | null, date: Date, timezone: string) => {
  // First, verify the subscription's status and provider.
  if (isActiveSubscription(subscription)) {
    // Check for any data age restrictions in the plan.
    if (subscription.Plan.dataAgeRestrictionInMonths !== null) {
      // Validate against the restriction if it exists.
      const isInvalid = isOlderThanNMonthsAgo(date, subscription.Plan.dataAgeRestrictionInMonths, timezone);

      return !isInvalid;
    } else {
      // If no restriction exists, the data age is valid.
      return true;
    }
  } else {
    // If the subscription is inactive, apply the default data age restriction for unsubscribed users.
    const isInvalid = isOlderThanNMonthsAgo(date, UNSUBSCRIBED_DATA_AGE_RESTRICTION_IN_MONTHS, timezone);

    return !isInvalid;
  }
};

/**
 * Validates if the number of users is permissible based on the subscription's plan restrictions.
 */
export const isValidNumberOfUsers = (subscription: Subscription | null, numberOfUsers: number): boolean => {
  // First, verify the subscription's status and provider.
  if (isActiveSubscription(subscription)) {
    // Check for any user number restrictions in the plan.
    if (subscription.Plan.maxUsersRestriction !== null) {
      // Validate against the restriction if it exists.
      return numberOfUsers < subscription.Plan.maxUsersRestriction;
    } else {
      // If no restriction exists, the number of users is valid.
      return true;
    }
  } else {
    // If the subscription is inactive, apply the default user number restriction for unsubscribed users.
    return numberOfUsers < UNSUBSCRIBED_N_OF_USERS_RESTRICTION;
  }
};
