import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { API, APIOutputs } from '../../api/trpc';
import { getQueryKey } from '@trpc/react-query';
import { useSessionUser } from '../../session/useSessionUser';
import { DeviceUpdateOutput } from '../Devices/useUpdateDevice';
import { IOUpdateOutput } from '../IOs/useUpdateIO';
import { ModulesUpdateOutput } from '../Modules/useUpdateModule';
import { UpdateOrganizationOutput } from './UpdateOrganizationMenuItem';
import { CreateModulesOutput } from '../Modules/AddModuleStepper/useCreateModule';
import { NewDeviceOutput } from '../Devices/AddDeviceStepper/useCreateDevice';

export type MyOrganizationOutput = APIOutputs['organizations']['get'];
export type MyOrganizationOutputDevice = MyOrganizationOutput['Device'][number];
export type MyOrganizationOutputModule = MyOrganizationOutputDevice['Module'][number];
export type MyOrganizationOutputIO = MyOrganizationOutputModule['IO'][number];
export type MyOrganizationOutputSubscription = Exclude<MyOrganizationOutput['Subscription'], null>;
export type MyOrganizationOutputSubscriptionPlan = MyOrganizationOutputSubscription['Plan'];
export type MyOrganizationOutputIOHardwareCapability = MyOrganizationOutputIO['HardwareCapability'];
export type MyOrganizationOutputIOHardwareDescriptionObject = MyOrganizationOutputIOHardwareCapability['IOHardwareDescriptionObject'];

export function useMyOrganization() {
  const user = useSessionUser();

  const queryClient = useQueryClient();

  const fetchQueryKey = useMemo(
    () => getQueryKey(API.organizations.get, { id: Number(user.defaultOrganization) }, 'query'),
    [user.defaultOrganization]
  );

  /**
   * This is a core query that is used everywhere.
   * Nothing works without this info.
   * So, cache time needs to be high to avoid returning undefined
   * and getting erros. (this is to avoid handling the loading states everywhere)
   * After 3hs of inactivity, the page will be reloaded
   * Anyway, the cache will be refreshed every 5min
   */
  const { data, error, isLoading, isFetching, refetch } = API.organizations.get.useQuery(
    { id: Number(user.defaultOrganization) },
    {
      keepPreviousData: true,
      staleTime: 5 * 60 * 1000,
      cacheTime: Infinity,
    }
  );

  /**
   * Getters
   */
  const getDevice = (id: number): MyOrganizationOutputDevice | null => {
    const device = data?.Device.find((device) => device.id === id);

    return device ?? null;
  };

  const getModule = (deviceId: number, id: number): MyOrganizationOutputModule | null => {
    const device = getDevice(deviceId);

    if (!device) {
      return null;
    }

    const module = device.Module.find((module) => module.id === id);

    return module ?? null;
  };

  const getIO = (deviceId: number, id: number): MyOrganizationOutputIO | null => {
    const device = getDevice(deviceId);
    if (!device) {
      return null;
    }

    type ReducedType = MyOrganizationOutputIO | null;
    const initialValue: ReducedType = null;

    const io = device.Module.reduce((io: ReducedType, module) => {
      const found = module.IO.find((io) => id === io.id);
      if (found) {
        return found;
      }

      return io;
    }, initialValue);

    return io;
  };

  /**
   * Cache updates
   */
  const onOrganizationUpdate = (response: UpdateOrganizationOutput) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, () => response);
  };

  /**
   * Device
   */
  const onDeviceCreate = (newDevice: NewDeviceOutput) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        return {
          ...old,
          Device: [...old.Device, newDevice],
        };
      }
    });
  };

  const onDeviceUpdate = (newDevice: DeviceUpdateOutput) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (newDevice.id === device.id) {
            return {
              ...device,
              name: newDevice.name,
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onDeviceSync = (deviceId: number) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (deviceId === device.id) {
            const updatedModules = device.Module.map((module) => ({
              ...module,
              isSynchronized: true,
            }));

            return {
              ...device,
              Module: updatedModules,
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onDeviceFirmwareUpdate = (deviceId: number, firmware: number) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (deviceId === device.id) {
            return {
              ...device,
              firmware: firmware,
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onDeviceConnected = React.useCallback(
    (deviceId: number) => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModules = device.Module.map((module) => ({
                ...module,
                isConnected: true,
              }));

              return {
                ...device,
                isConnected: true,
                Module: updatedModules,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    },
    [fetchQueryKey, queryClient]
  );

  const onDeviceConnectionConfirmed = (deviceId: number) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (deviceId === device.id) {
            const updatedModules = device.Module.map((module) => ({
              ...module,
              isConnected: true,
              isSynchronized: true,
            }));

            return {
              ...device,
              isConnected: true,
              Module: updatedModules,
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onDeviceDisconnected = React.useCallback(
    (deviceId: number) => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              return {
                ...device,
                isConnected: false,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    },
    [fetchQueryKey, queryClient]
  );

  /**
   * Module
   */
  const onModuleUpdate = (newModule: ModulesUpdateOutput) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (newModule.device === device.id) {
            const updatedModule = device.Module.map((module) =>
              module.id === newModule.id
                ? {
                    ...module,
                    name: newModule.name,
                    description: newModule.description,
                  }
                : module
            );

            return {
              ...device,
              Module: updatedModule,
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onModuleCreate = (newModule: CreateModulesOutput) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (newModule.device === device.id) {
            const addedModule = {
              ...newModule,
              isConnected: false,
              isSynchronized: false,
            };

            return {
              ...device,
              Module: [...device.Module, addedModule],
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onModuleSync = (deviceId: number, moduleId: number) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          if (deviceId === device.id) {
            const updatedModules = device.Module.map((module) =>
              module.id === moduleId
                ? {
                    ...module,
                    isSynchronized: true,
                    isConnected: true,
                  }
                : module
            );

            return {
              ...device,
              Module: updatedModules,
            };
          }

          return device;
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onModuleConfirmedByDevice = useCallback(
    (deviceId: number, moduleId: number) => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModules = device.Module.map((module) =>
                module.id === moduleId
                  ? {
                      ...module,
                      isSynchronized: true,
                      isConnected: true,
                    }
                  : module
              );

              return {
                ...device,
                Module: updatedModules,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    },
    [fetchQueryKey, queryClient]
  );

  const onModuleDelete = (deletedModuleId: number) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          return {
            ...device,
            Module: device.Module.filter((module) => module.id !== deletedModuleId),
          };
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  const onModuleDisconnected = React.useCallback(
    (deviceId: number, moduleId: number) => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModule = device.Module.map((module) => {
                if (module.id === moduleId) {
                  return {
                    ...module,
                    isConnected: false,
                  };
                }

                return module;
              });

              return {
                ...device,
                Module: updatedModule,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    },
    [fetchQueryKey, queryClient]
  );

  const onModuleConnected = React.useCallback(
    (deviceId: number, moduleId: number) => {
      queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
        if (old) {
          const updatedDevices = old.Device.map((device) => {
            if (deviceId === device.id) {
              const updatedModule = device.Module.map((module) => {
                if (module.id === moduleId) {
                  return {
                    ...module,
                    isConnected: true,
                  };
                }

                return module;
              });

              return {
                ...device,
                Module: updatedModule,
              };
            }

            return device;
          });

          return {
            ...old,
            Device: updatedDevices,
          };
        }
      });
    },
    [fetchQueryKey, queryClient]
  );

  /**
   * IO
   */
  const onIOUpdate = (newIO: IOUpdateOutput) => {
    queryClient.setQueryData<MyOrganizationOutput>(fetchQueryKey, (old) => {
      if (old) {
        const updatedDevices = old.Device.map((device) => {
          const updatedModules = device.Module.map((module) => {
            if (module.id === newIO.module) {
              const updatedIOs = module.IO.map((io) =>
                io.id === newIO.id
                  ? {
                      ...io,
                      name: newIO.name,
                      description: newIO.description,
                    }
                  : io
              );

              return {
                ...module,
                IO: updatedIOs,
              };
            }

            return module;
          });

          return {
            ...device,
            Module: updatedModules,
          };
        });

        return {
          ...old,
          Device: updatedDevices,
        };
      }
    });
  };

  // this is because we call to ensure data in the auth router layout component
  if (data === undefined) {
    window.location.reload();
  }

  const activeIOsCount = useMemo(() => {
    if (data === undefined) {
      return 0;
    }

    const modules = data.Device.flatMap((device) => device.Module);
    const synchronizedModules = modules.filter((module) => module.isSynchronized);
    const totalIOs = synchronizedModules.reduce((count, module) => count + module.IO.length, 0);
    return totalIOs;
  }, [data]);

  return {
    data: data as MyOrganizationOutput,
    error,
    isLoading,
    isFetching,
    activeIOsCount,
    getDevice,
    getModule,
    getIO,
    onOrganizationUpdate,
    onDeviceCreate,
    onDeviceUpdate,
    onDeviceSync,
    onDeviceFirmwareUpdate,
    onDeviceConnected,
    onDeviceDisconnected,
    onDeviceConnectionConfirmed,
    onModuleConnected,
    onModuleDisconnected,
    onModuleCreate,
    onModuleUpdate,
    onModuleDelete,
    onModuleSync,
    onModuleConfirmedByDevice,
    onIOUpdate,
    refetch,
  };
}
