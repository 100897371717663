import React from 'react';
import {
  Avatar,
  Box,
  BoxProps,
  Divider,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { Sidebar } from './Sidebar';
import { MessageDescriptor, defineMessages } from 'react-intl';
import { IconType } from 'react-icons';
import {
  FaArrowRightFromBracket as LogoutIcon,
  FaIdCard as ProfileIcon,
  FaShield as SecurityIcon,
  FaGear as PreferencesIcon,
  FaCircleExclamation as NotificationsIcon,
} from 'react-icons/fa6';
import { TranslatedMessage } from '../../i18n';
import { useSignOut } from '../../session/useSignOut';
import { useNavigate } from 'react-router-dom';
import { Scenes, sitemap } from '../../routing';
import { useSession } from '../../session/useSession';
import { useSessionUser } from '../../session/useSessionUser';
import { AuthenticatedSession } from '../../session/session';

const messages = defineMessages({
  account: {
    id: 'Sidebar.account_label',
    defaultMessage: 'Cuenta',
  },
  profile: {
    id: 'Sidebar.profile_entry',
    defaultMessage: 'Perfil',
  },
  preferences: {
    id: 'Sidebar.preferences_entry',
    defaultMessage: 'Preferencias',
  },
  notifications: {
    id: 'Sidebar.notificactions_entry',
    defaultMessage: 'Notificaciones',
  },
  security: {
    id: 'Sidebar.security_entry',
    defaultMessage: 'Seguridad',
  },
  logOut: {
    id: 'Account.sign_out_label',
    defaultMessage: 'Cerrar sesión',
  },
});

/****
 * The MIN_SCROLL constant defines the minimum scroll distance required to trigger the header's visibility toggle.
 * This helps to avoid unnecessary toggling when the user scrolls only a small amount.
 *
 * The SCROLL_TIMEOUT constant sets a delay before the scroll event handler processes the scroll action.
 * This delay helps to reduce the frequency of updates and improves performance by preventing rapid toggling.
 *
 * By moving the scroll handling logic to the callback queue using setTimeout, we mitigate the bounce issue with the sticky header.
 * This ensures that the header visibility changes are smoother and less jittery, providing a better user experience.
 ****/

const MIN_SCROLL = 10;
const MIN_SCROLL_DIFFERENCE = 50;
const SCROLL_TIMEOUT = 5;
interface HeaderProps {
  isSidebarVisible?: boolean;
}
export const Header: React.FC<HeaderProps> = (props) => {
  const { isSidebarVisible = true } = props;

  const [showHeader, setShowHeader] = React.useState(true);
  const lastScrollYRef = React.useRef(0);
  const scrollTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleScroll = React.useCallback(() => {
    const currentScrollY = window.scrollY;
    const scrollDifference = Math.abs(currentScrollY - lastScrollYRef.current);

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      if (scrollDifference > MIN_SCROLL_DIFFERENCE) {
        if (currentScrollY > lastScrollYRef.current && currentScrollY > MIN_SCROLL) {
          setShowHeader(false);
        } else {
          setShowHeader(true);
        }

        lastScrollYRef.current = currentScrollY;
      }
    }, SCROLL_TIMEOUT);
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [handleScroll]);

  return (
    <Box
      display="flex"
      width="full"
      position={'sticky'}
      zIndex={'banner'}
      background={'appBg'}
      top={'env(safe-area-inset-top)'}
      left={0}
      py={1}
      px={{ base: 2, md: 4 }}
      transform={showHeader ? 'translateY(0)' : 'translateY(-100%)'}
      transition="transform 0.3s ease-in-out"
    >
      <HStack opacity={showHeader ? 1 : 0}>{isSidebarVisible && <Sidebar />}</HStack>
      <Spacer />
      <AccountMenu opacity={showHeader ? 1 : 0} zIndex={'overlay'} />
    </Box>
  );
};

export const AccountMenu: React.FC<BoxProps> = (props) => {
  const { signOut } = useSignOut();
  const { user } = useSession<AuthenticatedSession>();
  const { picture } = useSessionUser();

  const userFullName = user.getUsername();

  const navigate = useNavigate();
  const getInitials = (name: string): string => {
    const words = name.split(' ');

    const initials = words.reduce((initials, word) => {
      if (!word) {
        return initials;
      }

      return initials.concat(word[0]);
    }, '');

    return initials.toUpperCase();
  };

  const navigateToProfile = () => {
    const path = sitemap[Scenes.PROFILE].path;

    navigate(path);
  };

  const navigateToPreferences = () => {
    const path = sitemap[Scenes.PROFILE].children.preferences.path;

    navigate(path);
  };

  const navigateToNotifications = () => {
    const path = sitemap[Scenes.PROFILE].children.notifications.path;

    navigate(path);
  };

  const navigateToSecurity = () => {
    const path = sitemap[Scenes.PROFILE].children.security.path;

    navigate(path);
  };

  return (
    <Box {...props}>
      <Menu>
        <MenuButton p={1}>
          {picture ? (
            <Image src={picture} loading="lazy" borderRadius={'full'} boxSize={12} objectFit="cover" />
          ) : (
            <Avatar name={userFullName} getInitials={getInitials} />
          )}
        </MenuButton>
        <MenuList>
          <ThemedMenuItem icon={ProfileIcon} label={messages.profile} onClick={navigateToProfile} />
          <ThemedMenuItem icon={PreferencesIcon} label={messages.preferences} onClick={navigateToPreferences} />
          <ThemedMenuItem icon={NotificationsIcon} label={messages.notifications} onClick={navigateToNotifications} />
          <ThemedMenuItem icon={SecurityIcon} label={messages.security} onClick={navigateToSecurity} />
          <Divider my={2} />
          <ThemedMenuItem icon={LogoutIcon} label={messages.logOut} onClick={signOut} color={'error'} />
        </MenuList>
      </Menu>
    </Box>
  );
};

interface ThemedMenuItemProps extends Omit<MenuItemProps, 'icon'> {
  icon: IconType;
  label: MessageDescriptor;
  onClick: () => void;
}
export const ThemedMenuItem: React.FC<ThemedMenuItemProps> = (props) => {
  const { label, icon, onClick, ...menuItemProps } = props;

  return (
    <MenuItem onClick={onClick} {...menuItemProps}>
      <HStack spacing={5}>
        <Icon as={icon} />
        <TranslatedMessage message={label} fontSize={'lg'} />
      </HStack>
    </MenuItem>
  );
};
