import React from 'react';
import { Flex, Heading, Stat, StatGroup, StatHelpText, StatLabel, StatNumber, Text, VStack } from '@chakra-ui/react';
import { TranslatedMessage } from '../../../i18n';
import { MarkdownPlanDescription } from '../../Backoffice/Plans/MarkdownPlanDescription';
import { calculateBillableVariables, calculatePrice } from 'common';
import { defineMessages, useIntl } from 'react-intl';
import { Plan } from './SubscribeToPlansAccordion';
import { useMyOrganization } from '../../Organizations/useMyOrganization';

const messages = defineMessages({
  pricePerMonth: {
    id: 'PlanItem.price_per_month',
    defaultMessage: '{price} /mes',
  },
  finalPrice: {
    id: 'PlanItem.final_price',
    defaultMessage: 'Precio final',
  },
  minPriceHelperDescription: {
    id: 'PlanItem.base_price_description',
    defaultMessage: 'Precio base + IVA',
  },
  minPriceAndVariablesHelperDescription: {
    id: 'PlanItem.base_price_and_variables_description',
    defaultMessage: 'Precio base + {count} variables + IVA ',
  },
  variablesHelperDescription: {
    id: 'PlanItem.variables_description',
    defaultMessage: '{count} variables + IVA',
  },
  minPriceAndMinQuantity: {
    id: 'PlanItem.base_price_and_min_quantity',
    defaultMessage: 'Base de {minPrice} hasta {minQuantity} variables',
  },
  minQuantity: {
    id: 'PlanItem.min_variables',
    defaultMessage: 'Minimo {minQuantity} variables',
  },
  pricePerVariable: {
    id: 'PlanItem.variable_price',
    defaultMessage: '{pricePerVariable} por variable',
  },
});

interface PlanItemProps {
  plan: Plan;
  SubscriptionButton: React.FC<{ plan: Plan }>;
}

export const SubscriptionPlanItem: React.FC<PlanItemProps> = (props) => {
  const { plan, SubscriptionButton } = props;

  const { activeIOsCount } = useMyOrganization();
  const intl = useIntl();
  const billableVariables = calculateBillableVariables(activeIOsCount, plan.minQuantity || undefined);
  const price = calculatePrice(billableVariables, plan.pricePerVariable, plan.minQuantity || undefined, plan.minPrice || undefined) / 100;

  return (
    <Flex direction={'column'} borderWidth={'1px'} borderRadius={'lg'} p={[3, 4]} gap={3} justifyContent={'space-between'} bg={'cardBg'}>
      <Flex direction={'column'} gap={2}>
        <Heading size="md" color={'fontColor'} textAlign={'center'}>
          {plan.name}
        </Heading>

        <StatGroup textAlign={'center'}>
          <Stat>
            <StatLabel>
              <TranslatedMessage color="fontColorLight" fontSize={'xs'} message={messages.finalPrice} />
            </StatLabel>

            <StatNumber>
              <TranslatedMessage
                message={messages.pricePerMonth}
                fontSize={'md'}
                values={{
                  price: (
                    <Text as="span" fontSize={'xl'} fontWeight="bold">
                      {intl.formatNumber(price, { style: 'currency', currency: plan.currency, currencyDisplay: 'code' })}
                    </Text>
                  ),
                }}
              />
            </StatNumber>

            <StatHelpText>
              {plan.minPrice && billableVariables > 0 && (
                <TranslatedMessage
                  fontSize={'2xs'}
                  message={messages.minPriceAndVariablesHelperDescription}
                  values={{ count: activeIOsCount }}
                />
              )}

              {plan.minPrice && billableVariables === 0 && (
                <TranslatedMessage fontSize={'2xs'} message={messages.minPriceHelperDescription} />
              )}

              {!plan.minPrice && (
                <TranslatedMessage fontSize={'2xs'} message={messages.variablesHelperDescription} values={{ count: activeIOsCount }} />
              )}
            </StatHelpText>
          </Stat>
        </StatGroup>

        {plan.description && (
          <Flex justifyContent={'center'}>
            <MarkdownPlanDescription description={plan.description} />
          </Flex>
        )}
      </Flex>

      <Flex direction={'column'} gap={2}>
        <SubscriptionButton plan={plan} />

        <VStack spacing={0}>
          <TranslatedMessage
            message={messages.pricePerVariable}
            color="fontColorLight"
            fontSize={'2xs'}
            values={{
              pricePerVariable: (
                <Text as="span" fontSize="2xs" fontWeight="bold">
                  {intl.formatNumber(plan.pricePerVariable / 100, {
                    style: 'currency',
                    currency: plan.currency,
                    currencyDisplay: 'symbol',
                  })}
                </Text>
              ),
            }}
          />

          {plan.minPrice && plan.minQuantity && (
            <TranslatedMessage
              message={messages.minPriceAndMinQuantity}
              color="fontColorLight"
              fontSize={'2xs'}
              values={{
                minPrice: (
                  <Text as="span" fontSize="2xs" fontWeight="bold">
                    {intl.formatNumber(plan.minPrice / 100, {
                      style: 'currency',
                      currency: plan.currency,
                      currencyDisplay: 'symbol',
                    })}
                  </Text>
                ),
                minQuantity: plan.minQuantity,
              }}
            />
          )}

          {!plan.minPrice && plan.minQuantity && (
            <TranslatedMessage
              message={messages.minQuantity}
              color="fontColorLight"
              fontSize={'2xs'}
              values={{
                minQuantity: plan.minQuantity,
              }}
            />
          )}
        </VStack>
      </Flex>
    </Flex>
  );
};
