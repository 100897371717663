import React from 'react';
import { Icon, IconButton, useDisclosure } from '@chakra-ui/react';
import { AppDrawer } from './AppDrawer';
import { RiMenuLine as MenuIcon } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

export const Sidebar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();

  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  const [, startTransition] = React.useTransition();

  const onClick = () => {
    startTransition(() => onOpen());
  };

  React.useEffect(() => {
    startTransition(() => onClose());
  }, [onClose, pathname]);

  return (
    <>
      <IconButton size={'lg'} aria-label="menu" onClick={onClick} ref={buttonRef} variant="ghost" icon={<Icon as={MenuIcon} />} />
      <AppDrawer buttonRef={buttonRef} onClose={onClose} isOpen={isOpen} />
    </>
  );
};
