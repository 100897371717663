import ReactMarkdown from 'react-markdown';
import { FaCheck as ListItemIcon } from 'react-icons/fa6';
import { List, ListIcon, ListItem } from '@chakra-ui/react';

interface MarkdownPlanDescriptionProps {
  description: string;
}
export const MarkdownPlanDescription: React.FC<MarkdownPlanDescriptionProps> = (props) => {
  const { description } = props;

  return (
    <ReactMarkdown
      components={{
        ul: ({ children }) => (
          <List spacing={1} fontSize={'sm'}>
            {children}
          </List>
        ),
        ol: ({ children }) => (
          <List spacing={1} fontSize={'sm'}>
            {children}
          </List>
        ),
        li: ({ children }) => (
          <ListItem textAlign={'left'} fontSize={'sm'}>
            <ListIcon as={ListItemIcon} color={'subscriptionHover'} />
            {children}
          </ListItem>
        ),
      }}
    >
      {description}
    </ReactMarkdown>
  );
};
