import React, { useEffect, useMemo, useState } from 'react';
import { useSessionUser } from '../../../session/useSessionUser';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Flex,
  Grid,
  Heading,
} from '@chakra-ui/react';
import { SubscriptionPlanItem } from './SubscriptionPlanItem';
import { Plan, PlanListOutput } from './SubscribeToPlansAccordion';
import { calculateBillableVariables, calculatePrice, SubscriptionProvider } from 'common';
import { Locales, TranslatedMessage } from '../../../i18n';
import { providerDescriptionMessage } from '../commonSubscriptionMessages';
import { useMyOrganization } from '../../Organizations/useMyOrganization';

interface PlansAccordionProps extends AccordionProps {
  plans: PlanListOutput;
  SubscriptionButton: React.FC<{ plan: Plan }>;
}
export const PlansAccordion: React.FC<PlansAccordionProps> = (props) => {
  const { plans, SubscriptionButton, ...accordionProps } = props;

  const [index, setIndex] = useState<Array<number> | null>(null);
  const user = useSessionUser();
  const { activeIOsCount } = useMyOrganization();

  //  sort and group by provider
  const sortedPlans = useMemo(() => {
    if (plans === undefined || plans.length === 0) {
      return [];
    }

    // group plans by provider
    const grouped = plans.reduce((map, plan) => {
      const provider = plan.provider;

      const group = map.get(provider) ?? [];
      group.push(plan);
      map.set(provider, group);

      return map;
    }, new Map<SubscriptionProvider, Array<Plan>>());

    // sort it so if user locale is es-AR, 'MercadoPago' comes first
    const sortedProviders = Array.from(grouped.entries()).sort(([providerA], [providerB]) => {
      if (user.locale === Locales.ES_AR) {
        if (providerA === SubscriptionProvider.MERCADO_PAGO) return -1;
        if (providerB === SubscriptionProvider.MERCADO_PAGO) return 1;
      } else {
        if (providerA === SubscriptionProvider.STRIPE) return -1;
        if (providerB === SubscriptionProvider.STRIPE) return 1;
      }
      return 0;
    });

    // sort plans by price
    const sorted = sortedProviders.map(([provider, providerPlans]): [SubscriptionProvider, Array<Plan>] => {
      const sortedPlansByPrice: Array<Plan> = providerPlans.sort((planA, planB) => {
        const priceA = calculatePlanPrice(planA, activeIOsCount);
        const priceB = calculatePlanPrice(planB, activeIOsCount);

        return priceA - priceB; // sort in ascending order
      });

      return [provider, sortedPlansByPrice];
    });

    return sorted;
  }, [plans, user.locale, activeIOsCount]);

  // first plan should be open by default
  useEffect(() => {
    if (sortedPlans.length > 0 && index === null) {
      setIndex([0]);
    }
  }, [sortedPlans.length, index]);

  const onChange = (index: number) => {
    setIndex([index]);
  };

  return (
    <Accordion {...accordionProps} index={index || undefined} onChange={onChange}>
      {sortedPlans.map(([provider, plans]) => (
        <AccordionItem key={provider} border={'none'}>
          <AccordionButton justifyContent={'space-between'} borderRadius={'md'} px={[0, 4]}>
            <Flex direction={'column'} flex={1} alignItems={'start'} my={4}>
              <Heading size={'md'}>{provider}</Heading>
              <TranslatedMessage message={providerDescriptionMessage[provider]} fontSize={'sm'} />
            </Flex>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel px={[0, 4]}>
            <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={[4, 6]}>
              {plans.map((plan) => (
                <SubscriptionPlanItem key={plan.id} plan={plan} SubscriptionButton={SubscriptionButton} />
              ))}
            </Grid>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const calculatePlanPrice = (plan: Plan, activeIOsCount: number): number => {
  const billableVariables = calculateBillableVariables(activeIOsCount, plan.minQuantity || undefined);
  return calculatePrice(billableVariables, plan.pricePerVariable, plan.minQuantity || undefined, plan.minPrice || undefined) / 100;
};
