import React from 'react';
import { API, APIOutputs } from '../../../api/trpc';
import { AccordionProps, Box, Flex, Grid, Skeleton } from '@chakra-ui/react';
import { APIError } from '../../Error/ErrorViewer';
import { TranslatedMessage } from '../../../i18n';
import { defineMessages } from 'react-intl';
import { PlansAccordion } from './PlansAccordion';

const messages = defineMessages({
  noPlansAvailable: {
    id: 'SubscribeToPlansAccordionProps.no_plans_available',
    defaultMessage: 'No hay planes disponibles en este momento',
  },
});

export type PlanListOutput = APIOutputs['subscriptions']['listPlans'];
export type Plan = PlanListOutput[number];

interface SubscribeToPlansAccordionProps extends AccordionProps {
  SubscriptionButton: React.FC<{ plan: Plan }>;
}
export const SubscribeToPlansAccordion: React.FC<SubscribeToPlansAccordionProps> = (props) => {
  const { SubscriptionButton, ...accordionProps } = props;

  const { data: plans, isLoading, error } = API.subscriptions.listPlans.useQuery();

  if (isLoading) {
    return <PlansAccordionSkeleton />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (plans === undefined || plans.length === 0) {
    return (
      <Flex my={6} justifyContent={'center'} alignItems={'center'}>
        <TranslatedMessage message={messages.noPlansAvailable} color="fontColorLight" />
      </Flex>
    );
  }

  return <PlansAccordion plans={plans} SubscriptionButton={SubscriptionButton} {...accordionProps} />;
};

const PlansAccordionSkeleton = () => {
  return (
    <Flex direction={'column'} py={2} gap={2}>
      <Box height={24} width={'full'} justifyContent={'start'} alignContent={'center'} my={4}>
        <Skeleton height={12} width={52} endColor={'bg500'} startColor={'bg100'} />
      </Box>
      <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={[4, 6]} px={[0, 4]}>
        <Skeleton height={72} endColor={'bg500'} startColor={'bg100'} borderRadius={'lg'} />

        <Skeleton height={72} endColor={'bg500'} startColor={'bg100'} borderRadius={'lg'} />
      </Grid>
      <Box height={24} width={'full'} justifyContent={'start'} alignContent={'center'} my={4}>
        <Skeleton height={12} width={52} endColor={'bg500'} startColor={'bg100'} />
      </Box>
    </Flex>
  );
};
