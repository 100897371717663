import { getTimezoneOffset } from 'date-fns-tz';
import { generateUniqueStringId } from './IDGeneration';
import { assertUnreachable, validateArray, validateNonEmptyObject, validateNumber, validateObjectKey, validateString } from './validations';

export enum AutomationTemplateType {
  SIMPLE_COMPARATOR = 'simpleComparator',
  SCHEDULE = 'schedule',
  MULTIPLE_SCHEDULE = 'multipleSchedule',
  CYCLE_SCHEDULE = 'cycleSchedule',
  ADVANCED = 'advanced',
}

// operators
export enum ComparisonOperator {
  GreaterThan = 'greaterThan',
  LessThan = 'lessThan',
}

export enum LogicalOperator {
  AND = 'and',
  OR = 'or',
}

// nodes
export enum NodeType {
  COMPARISON = 'comparison',
  LOGICAL = 'logical',
}

export enum ReferenceType {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
  TIME = 'time',
}

/**
 * Daily by default
 * Optionally can be set for custom days of week:
 * 0 - Sun
 * 1 - Mon
 * ...
 */
export interface TimeValue {
  type: ReferenceType.TIME;
  hours: number;
  minutes: number;
  seconds: number;
  daysOfWeek?: number[];
}

export interface DynamicReference {
  type: ReferenceType.DYNAMIC;
  deviceId: number;
  moduleId: number;
  ioId: number;
}

export interface StaticReference {
  type: ReferenceType.STATIC;
  value: number;
}

// only one value, the another one is the current time
export interface TimeComparisonNode {
  id: string;
  type: NodeType.COMPARISON;
  operator: ComparisonOperator;
  value: TimeValue;
}

export interface IOComparisonNode {
  id: string;
  type: NodeType.COMPARISON;
  operator: ComparisonOperator;
  value: DynamicReference;
  reference: StaticReference;
  safetyState: number;
}

export type ComparisonNode = TimeComparisonNode | IOComparisonNode;

export interface LogicalNode {
  id: string;
  type: NodeType.LOGICAL;
  operator: LogicalOperator;
  children: Array<TriggerNode>;
}

export type TriggerNode = ComparisonNode | LogicalNode;

/**
 * This should map the trigger boolean value to
 * a different numeric one.
 * We may use it when analog outputs are implemented
 */
export enum TransferFunction {
  IDENTITY = 'identity',
}

/**
 * This allows for more dynamic automations without the need to overly complicate triggers.
 * For instance, if we want the output to pulse every 15 seconds during a specific time frame.
 */
export enum OutputModeType {
  CONSTANT = 'constant',
  PULSE = 'pulse',
}

export interface OutputModeConstant {
  type: OutputModeType.CONSTANT;
}

export interface OutputModePulse {
  type: OutputModeType.PULSE;
  timeOn: number;
  timeOff: number;
}

export type OutputMode = OutputModeConstant | OutputModePulse;

/**
 * This should be use for protecting external devices
 * connected to our outputs
 */
export enum ProtectionType {
  DEBOUNCE = 'debounce',
}

interface DebounceProtection {
  delayInSeconds: number;
}

export type Protection = DebounceProtection;

export type Automation = {
  trigger: TriggerNode;
  transfer: TransferFunction;
  outputMode: OutputMode;
  protection?: Protection;
};

/**
 * This is for minifing an automation for devices
 */

enum MinifiedNodeType {
  IO_COMPARISON = 0,
  TIME_COMPARISON = 1,
  LOGICAL = 2,
}

const MinifiedOutputModeMap: Record<OutputModeType, number> = {
  [OutputModeType.CONSTANT]: 0,
  [OutputModeType.PULSE]: 1,
};

const MinifiedLogicalOperatorMap: Record<LogicalOperator, number> = {
  [LogicalOperator.OR]: 0,
  [LogicalOperator.AND]: 1,
};

const MinifiedComparisonOperatorMap: Record<ComparisonOperator, number> = {
  [ComparisonOperator.LessThan]: 0,
  [ComparisonOperator.GreaterThan]: 1,
};

type MinifiedLogicalNode = {
  ty: MinifiedNodeType.LOGICAL; // node type
  op: number; // operator
  ch: Array<MinifiedNode>; // children
};

type MinifiedIOComparisonNode = {
  ty: MinifiedNodeType; // node type
  sp: number; // set point
  sv: number; // safety value
  cty: number; // comparison type
  ma: number; // module id
  iok: number; // io id
};

type MinifiedTimeComparisonNode = {
  ty: MinifiedNodeType; // node type
  sp: number; // set point
  cty: number; // comparison type
  dy?: number; // days of weeks mask
};

type MinifiedNode = MinifiedLogicalNode | MinifiedIOComparisonNode | MinifiedTimeComparisonNode;
export interface MinifiedAutomation {
  rt: MinifiedNode; // trigger node
  md: number; // output mode
  to?: number; // time on
  tf?: number; // time off
  dd?: number; // debounce delay
}

/**
 *
 * boolean validation checking
 *
 */
export function isValidLogicalOperator(operator: string): boolean {
  return Object.values(LogicalOperator).includes(operator as LogicalOperator);
}

export function isValidComparisonOperator(operator: string): boolean {
  return Object.values(ComparisonOperator).includes(operator as ComparisonOperator);
}

export function isValidNodeType(type: string): boolean {
  return Object.values(NodeType).includes(type as NodeType);
}

export function isValidReferenceType(type: string): boolean {
  return Object.values(ReferenceType).includes(type as ReferenceType);
}

export function isValidTransferFunction(functionName: string): boolean {
  return Object.values(TransferFunction).includes(functionName as TransferFunction);
}

export function isValidOutputAdaptation(adaptation: string): boolean {
  return Object.values(OutputModeType).includes(adaptation as OutputModeType);
}

export function isValidAutomationTemplateType(templateType: string): templateType is AutomationTemplateType {
  return Object.values(AutomationTemplateType).includes(templateType as AutomationTemplateType);
}

/**
 *
 * Assertions
 *
 */
export function validateNodeType(type: string): asserts type is NodeType {
  if (!Object.values(NodeType).includes(type as NodeType)) {
    throw new Error('Invalid node type');
  }
}

export function validateComparisonOperator(operator: string): asserts operator is ComparisonOperator {
  if (!Object.values(ComparisonOperator).includes(operator as ComparisonOperator)) {
    throw new Error('Invalid comparison operator');
  }
}

export function validateReferenceType(type: string): asserts type is ReferenceType {
  if (!Object.values(ReferenceType).includes(type as ReferenceType)) {
    throw new Error('Invalid reference type');
  }
}

export function validateLogicalOperator(operator: string): asserts operator is LogicalOperator {
  if (!Object.values(LogicalOperator).includes(operator as LogicalOperator)) {
    throw new Error('Invalid logical operator');
  }
}

export function validateTemplate(template: string): asserts template is AutomationTemplateType {
  if (!Object.values(AutomationTemplateType).includes(template as AutomationTemplateType)) {
    throw new Error('Invalid template type');
  }
}

export function validateTimeValue(timeValue: unknown): asserts timeValue is TimeValue {
  validateNonEmptyObject(timeValue);

  validateObjectKey(timeValue, 'type');
  validateObjectKey(timeValue, 'hours');
  validateObjectKey(timeValue, 'minutes');
  validateObjectKey(timeValue, 'seconds');

  const { type, hours, minutes, seconds, daysOfWeek } = timeValue;

  validateString(type);
  if (type !== ReferenceType.TIME) {
    throw new Error('Invalid time value type');
  }

  validateNumber(hours);
  if (hours < 0 || hours > 23) {
    throw new Error('Hours must be between 0 and 23');
  }

  validateNumber(minutes);
  if (minutes < 0 || minutes > 59) {
    throw new Error('Minutes must be between 0 and 59');
  }

  validateNumber(seconds);
  if (seconds < 0 || seconds > 59) {
    throw new Error('Seconds must be between 0 and 59');
  }

  if (daysOfWeek !== undefined) {
    validateArray(daysOfWeek);
    daysOfWeek.forEach((day) => {
      validateNumber(day);

      if (day < 0 || day > 6) {
        throw new Error('Day of week must be between 0 (Sunday) and 6 (Saturday)');
      }
    });
  }
}

export function validateIOValue(ioValue: unknown): asserts ioValue is DynamicReference {
  validateNonEmptyObject(ioValue);

  validateObjectKey(ioValue, 'deviceId');
  validateObjectKey(ioValue, 'moduleId');
  validateObjectKey(ioValue, 'ioId');

  const { ioId, moduleId, deviceId } = ioValue;

  validateNumber(deviceId);
  validateNumber(moduleId);
  validateNumber(ioId);
}

export function validateStaticValue(staticValue: unknown): asserts staticValue is StaticReference {
  validateNonEmptyObject(staticValue);
  validateObjectKey(staticValue, 'value');

  const { value } = staticValue;

  validateNumber(value);
}

export function validateTransferFunction(functionName: string): asserts functionName is TransferFunction {
  if (!Object.values(TransferFunction).includes(functionName as TransferFunction)) {
    throw new Error('Invalid transfer function');
  }
}

export function validateOutputModeType(mode: string): asserts mode is OutputModeType {
  if (!Object.values(OutputModeType).includes(mode as OutputModeType)) {
    throw new Error('Invalid output mode');
  }
}

export function validateOutputMode(value: unknown): asserts value is OutputMode {
  validateNonEmptyObject(value);
  validateObjectKey(value, 'type');

  const { type } = value;

  validateString(type);
  validateOutputModeType(type);

  switch (type) {
    case OutputModeType.CONSTANT:
      break;

    case OutputModeType.PULSE: {
      validateObjectKey(value, 'timeOn');
      validateObjectKey(value, 'timeOff');

      const { timeOn, timeOff } = value;

      validateNumber(timeOn);
      validateNumber(timeOff);
      break;
    }
    default:
      assertUnreachable('Invalid output mode type', type);
  }
}

export function validateNode(node: unknown): asserts node is TriggerNode {
  validateNonEmptyObject(node);

  validateObjectKey(node, 'id');
  validateObjectKey(node, 'type');
  validateObjectKey(node, 'operator');

  const { id, type, operator } = node;

  validateString(id);
  validateString(operator);
  validateString(type);
  validateNodeType(type);

  switch (type) {
    case NodeType.COMPARISON: {
      const { value } = node;

      validateNonEmptyObject(value);
      validateObjectKey(value, 'type');
      validateString(value.type);
      validateReferenceType(value.type);

      const { type: referenceType } = value;

      validateComparisonOperator(operator);

      if (referenceType === ReferenceType.TIME) {
        validateTimeValue(value);
        break;
      }

      const { reference, safetyState } = node;

      validateIOValue(value);
      validateStaticValue(reference);
      validateNumber(safetyState);

      break;
    }

    case NodeType.LOGICAL: {
      validateLogicalOperator(operator);
      validateObjectKey(node, 'children');

      const { children } = node;
      validateArray(children);

      if (children.length < 2) {
        throw new Error('Insufficient children.');
      }

      children.forEach(validateNode);
      break;
    }
  }
}

// this only check the schema, it does not check valid children in logical
export function softValidateNode(node: unknown): asserts node is TriggerNode {
  validateNonEmptyObject(node);

  validateObjectKey(node, 'id');
  validateObjectKey(node, 'type');
  validateObjectKey(node, 'operator');

  const { id, type, operator } = node;

  validateString(id);
  validateString(operator);
  validateString(type);
  validateNodeType(type);

  switch (type) {
    case NodeType.COMPARISON: {
      const { value } = node;

      validateNonEmptyObject(value);
      validateObjectKey(value, 'type');
      validateString(value.type);
      validateReferenceType(value.type);

      const { type: referenceType } = value;

      validateComparisonOperator(operator);

      if (referenceType === ReferenceType.TIME) {
        validateTimeValue(value);
        break;
      }

      const { reference, safetyState } = node;

      validateIOValue(value);
      validateStaticValue(reference);
      validateNumber(safetyState);

      break;
    }

    case NodeType.LOGICAL: {
      validateObjectKey(node, 'children');

      const { children } = node;
      validateArray(children);

      children.forEach(softValidateNode);
      break;
    }
  }
}

export function validateAutomation(automation: unknown): asserts automation is Automation {
  validateNonEmptyObject(automation);

  validateObjectKey(automation, 'trigger');
  validateObjectKey(automation, 'transfer');
  validateObjectKey(automation, 'outputMode');

  const { trigger, transfer, outputMode } = automation;

  validateNode(trigger);

  validateString(transfer);
  validateTransferFunction(transfer);

  validateNonEmptyObject(outputMode);
  validateOutputMode(outputMode);

  if ('protection' in automation) {
    const protection = automation.protection;

    validateNonEmptyObject(protection);
    validateObjectKey(protection, 'delayInSeconds');
    validateNumber(protection.delayInSeconds);
  }
}

// minified
function validateMinifiedIOComparisonNode(node: MinifiedIOComparisonNode): void {
  validateNumber(node.sp);
  validateNumber(node.cty);
  validateNumber(node.sv);
  validateNumber(node.ma);
  validateNumber(node.iok);
}

function validateMinifiedTimeComparisonNode(node: MinifiedTimeComparisonNode): void {
  validateNumber(node.sp);
  validateNumber(node.cty);
  if ('dy' in node) {
    validateNumber(node.dy);
  }
}

function validateMinifiedLogicalNode(node: MinifiedLogicalNode): void {
  validateNumber(node.op);
  validateArray(node.ch);
  node.ch.forEach(validateMinifiedNode);
}

function validateMinifiedNode(node: unknown): asserts node is MinifiedNode {
  validateNonEmptyObject(node);
  validateObjectKey(node, 'ty');

  switch (node.ty) {
    case MinifiedNodeType.IO_COMPARISON:
      validateMinifiedIOComparisonNode(node as MinifiedIOComparisonNode);
      break;

    case MinifiedNodeType.TIME_COMPARISON:
      validateMinifiedTimeComparisonNode(node as MinifiedTimeComparisonNode);
      break;

    case MinifiedNodeType.LOGICAL:
      validateMinifiedLogicalNode(node as MinifiedLogicalNode);
      break;

    default:
      throw new Error('Invalid minified node type');
  }
}

export function validateMinifiedAutomation(minifiedAutomation: unknown): asserts minifiedAutomation is MinifiedAutomation {
  validateNonEmptyObject(minifiedAutomation);

  validateObjectKey(minifiedAutomation, 'rt');
  validateObjectKey(minifiedAutomation, 'md');
  const { rt, md, to, tf, dd } = minifiedAutomation;

  validateMinifiedNode(rt);
  validateNumber(md);

  if (to !== undefined) {
    validateNumber(to);
  }

  if (tf !== undefined) {
    validateNumber(tf);
  }

  if (dd !== undefined) {
    validateNumber(dd);
  }
}

/**
 *
 * Builders
 *
 */
export class AutomationBuilder {
  trigger: TriggerNode;
  transfer: TransferFunction;
  outputMode: OutputMode;
  protection?: Protection;

  constructor(trigger: TriggerNode, transfer?: TransferFunction, outputMode?: OutputMode, protection?: Protection) {
    this.trigger = trigger;
    this.transfer = transfer ?? TransferFunction.IDENTITY;
    this.outputMode = outputMode ?? { type: OutputModeType.CONSTANT };
    this.protection = protection;
  }

  get(): Automation {
    return {
      trigger: this.trigger,
      transfer: this.transfer,
      outputMode: this.outputMode,
      protection: this.protection,
    };
  }

  getDevice(): number | null {
    const extractDeviceId = (node: TriggerNode): number | null => {
      if (node.type === NodeType.COMPARISON && 'reference' in node) {
        return node.value.deviceId;
      } else if (node.type === NodeType.LOGICAL) {
        // iterate children
        for (const child of node.children) {
          const result = extractDeviceId(child);
          if (result !== null) return result;
        }
      }

      return null;
    };

    return extractDeviceId(this.trigger);
  }

  getIOs(): Array<number> {
    const ios: Array<number> = [];

    const pushIOIdsToArray = (node: TriggerNode) => {
      if (node.type === NodeType.COMPARISON && 'reference' in node) {
        ios.push(node.value.ioId);
      } else if (node.type === NodeType.LOGICAL) {
        node.children.forEach(pushIOIdsToArray);
      }
    };

    pushIOIdsToArray(this.trigger);

    return ios;
  }

  serialize(): string {
    return JSON.stringify(this.get());
  }

  minify(timezone?: string): MinifiedAutomation {
    // trigger nodes
    const minifyTriggerNode = (node: TriggerNode): MinifiedNode => {
      if (node.type === NodeType.LOGICAL) {
        // Logical
        return {
          ty: MinifiedNodeType.LOGICAL,
          op: MinifiedLogicalOperatorMap[node.operator],
          ch: node.children.map(minifyTriggerNode),
        };
      } else {
        // io comparison node
        if ('reference' in node) {
          return {
            ty: MinifiedNodeType.IO_COMPARISON,
            cty: MinifiedComparisonOperatorMap[node.operator],
            sp: node.reference.value * 100, // devices use hundredths,
            sv: node.safetyState,
            ma: node.value.moduleId,
            iok: node.value.ioId,
          };
        } else {
          const offsetInMilliSeconds = timezone ? getTimezoneOffset(timezone) : 0;
          const offsetInSeconds = isNaN(offsetInMilliSeconds) ? 0 : offsetInMilliSeconds / 1000;

          /**
           * if the I get a negative number after appliying the offset,
           * it means the right time should be in the previous day.
           * but the comparison is daily so, adding 1 day in seconds is enough
           */
          const secondsFromStartOfDay = offsetInSeconds + node.value.hours * 3600 + node.value.minutes * 60 + node.value.seconds;
          const secondsFromStartOfDayFixed = secondsFromStartOfDay < 0 ? secondsFromStartOfDay + 24 * 3600 : secondsFromStartOfDay;

          /**
           * TODO
           * the above consideration may require some changes for daysOfWeek too.
           */
          const daysOfWeekMask = node.value.daysOfWeek?.reduce((mask, day) => Math.pow(2, day) + mask, 0);

          return {
            ty: MinifiedNodeType.TIME_COMPARISON,
            cty: MinifiedComparisonOperatorMap[node.operator],
            sp: secondsFromStartOfDayFixed,
            dy: daysOfWeekMask,
          };
        }
      }
    };

    const automation = this.get();
    // debounce delay in seconds
    const dd = automation.protection?.delayInSeconds;

    return {
      rt: minifyTriggerNode(automation.trigger),
      md: MinifiedOutputModeMap[automation.outputMode.type],
      to: automation.outputMode.type === OutputModeType.PULSE ? automation.outputMode.timeOn : undefined,
      tf: automation.outputMode.type === OutputModeType.PULSE ? automation.outputMode.timeOff : undefined,
      dd,
    };
  }

  /**
   /**
    * This function calculates the useful size of the automation that will be handled by the device.
    * It is significantly smaller than the actual size it occupies in the database.
    * The creation/update mutation handles the database size restriction.
    * This function is simply used to estimate the real weight that the device will need to manage.
    */
  size(): number {
    const minified = this.minify();

    return JSON.stringify(minified).length;
  }

  updateTrigger(newTriger: TriggerNode) {
    this.trigger = newTriger;
  }

  updateTransfer(newTransfer: TransferFunction) {
    this.transfer = newTransfer;
  }

  updateOutput(newOutputMode: OutputMode) {
    this.outputMode = newOutputMode;
  }

  updateProtection(newProtection: Protection) {
    this.protection = newProtection;
  }

  static fromRaw(raw: string): AutomationBuilder {
    const json = JSON.parse(raw) as unknown;

    validateAutomation(json);

    return new AutomationBuilder(json.trigger, json.transfer, json.outputMode, json.protection);
  }
}

export class IOComparisonNodeBuilder {
  id: string;
  operator: ComparisonOperator;
  value: DynamicReference;
  reference: StaticReference;
  safetyState: number;

  constructor(operator: ComparisonOperator, value: DynamicReference, reference: StaticReference, safetyState: number) {
    this.id = generateUniqueStringId();

    this.operator = operator;
    this.value = value;
    this.reference = reference;
    this.safetyState = safetyState;
  }

  build(): IOComparisonNode {
    return {
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
      reference: this.reference,
      safetyState: this.safetyState,
    };
  }

  updateOperator(operator: ComparisonOperator) {
    this.operator = operator;
  }

  updateValue(value: DynamicReference) {
    this.value = value;
  }

  updateReference(value: StaticReference) {
    this.reference = value;
  }

  validate() {
    validateNode({
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
      reference: this.reference,
      safetyState: this.safetyState,
    });
  }
}

export class TimeComparisonNodeBuilder {
  id: string;
  operator: ComparisonOperator;
  value: TimeValue;

  constructor(operator: ComparisonOperator, time: TimeValue) {
    this.id = generateUniqueStringId();

    this.operator = operator;
    this.value = time;
  }

  build(): TimeComparisonNode {
    return {
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
    };
  }

  validate() {
    validateNode({
      id: this.id,
      type: NodeType.COMPARISON,
      operator: this.operator,
      value: this.value,
    });
  }
}

export class LogicalNodeBuilder {
  id: string;
  operator: LogicalOperator;
  children: Array<TriggerNode>;

  constructor(operator: LogicalOperator, children: Array<TriggerNode>) {
    this.id = generateUniqueStringId();

    this.operator = operator;
    this.children = children;
  }

  addChildren(newNode: TriggerNode) {
    this.children.push(newNode);
  }

  cleanChildren() {
    this.children = [];
  }

  removeChildren(nodeId: string) {
    const filtered = this.children.filter((node) => node.id !== nodeId);

    this.children = filtered;
  }

  replaceChildren(nodeId: string, newNode: TriggerNode) {
    const updated = this.children.map((node) => (node.id !== nodeId ? newNode : node));

    this.children = updated;
  }
  updateOperator(newOperator: LogicalOperator) {
    this.operator = newOperator;
  }

  validate() {
    validateNode({
      id: this.id,
      type: NodeType.LOGICAL,
      operator: this.operator,
      children: this.children,
    });
  }

  build(): LogicalNode {
    return {
      id: this.id,
      type: NodeType.LOGICAL,
      operator: this.operator,
      children: this.children,
    };
  }

  serialize(): string {
    const node = this.build();

    return JSON.stringify(node);
  }
}
