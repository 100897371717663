import { SubscriptionProvider } from 'common';
import { API, APIInputs, APIOutputs } from '../../api/trpc';

export type CreateSubscriptionInput = APIInputs['subscriptions']['createCheckoutUrl'];
export type CreateSubscriptionOutput = APIOutputs['subscriptions']['createCheckoutUrl'];

export const useCreateSubscription = () => {
  const onSuccess = (checkout: CreateSubscriptionOutput) => {
    if (checkout.provider === SubscriptionProvider.MERCADO_PAGO) {
      const mercadoPagoUrl = `mercadopago://pay?url=${checkout.url}`;

      // Intenta abrir la app
      window.location.href = mercadoPagoUrl;

      // fallback after a while
      setTimeout(() => {
        window.location.href = checkout.url;
      }, 1500);
    } else {
      window.location.href = checkout.url;
    }
  };

  const { mutate: createSubscriptionToPlan, isLoading, error } = API.subscriptions.createCheckoutUrl.useMutation({ onSuccess });

  const onSubmit = (planId: number | null) => {
    if (planId !== null) {
      createSubscriptionToPlan({ plan: planId });
    }
  };

  return { onSubmit, isLoading, error };
};
