import { Button, ButtonProps, Flex, Icon } from '@chakra-ui/react';
import { useCreateSubscription } from '../useCreateSubsciption';
import { Plan } from './SubscribeToPlansAccordion';
import { TranslatedMessage } from '../../../i18n';
import { commonSubscriptionMessages, providerIcon } from '../commonSubscriptionMessages';
import { APIErrorFormatter, defaultFormatter } from '../../Error/formatter';
import { APIError } from '../../Error/ErrorViewer';

interface SubscribeToPlanButtonProps extends ButtonProps {
  plan: Plan;
}
export const SubscribeToPlanButton: React.FC<SubscribeToPlanButtonProps> = (props) => {
  const { plan, ...buttonProps } = props;

  const { onSubmit, isLoading, error } = useCreateSubscription();

  const onClick = () => {
    onSubmit(plan.id);
  };

  return (
    <Flex direction={'column'} gap={2}>
      <Button
        leftIcon={<Icon as={providerIcon[plan.provider]} fontSize={'2xl'} />}
        iconSpacing={2}
        alignItems={'center'}
        variant={'subscriptionGradient'}
        isLoading={isLoading}
        onClick={onClick}
        {...buttonProps}
      >
        <TranslatedMessage message={commonSubscriptionMessages.subscribe} />
      </Button>

      {error && <APIError error={error} formatError={subscriptionErrorFormatter} />}
    </Flex>
  );
};

const subscriptionErrorFormatter: APIErrorFormatter = (error) => {
  const { data } = error;

  switch (data?.cause) {
    case 'already_subscribed':
      return commonSubscriptionMessages.alreadySubscribedError;

    case 'plan_not_found':
      return commonSubscriptionMessages.planNotFoundError;

    case 'cant_reactivate_subscription':
      return commonSubscriptionMessages.subscriptionCantBeReactivated;

    default:
      return defaultFormatter(error);
  }
};
